<template>
  <div class="main">
    <div class="body-content">
      <h1 class="title">Transaction Entry</h1>

      <div v-if="isLoading">Loading Container list...</div>
      <div v-else-if="error">Error loading Container list: {{ error }}</div>

      <div v-else>
        <div class="table-container">
          <table class="movement-table table-design">
            <thead>
              <tr>
                <th class="field-100">Container</th>
                <th class="field-100">Party Name</th>
                <th class="field-100">Vessel Name</th>
                <th class="field-100">Voyage Name</th>
                <th class="field-100">Movement Name</th>
                <th class="field-100">Location Name</th>
                <th class="field-100">Seal No</th>
                <th class="field-100">ISPT Date</th>
                <th class="field-100">Time</th>
                <th class="field-100" v-if="shouldShowGrossWeight">Gross Weight</th>
                <th class="field-100" v-if="shouldShowCFSID">CFS ID</th>
                <th class="field-100" v-if="shouldShowPOL">POL</th>
                <th class="field-100" v-if="shouldShowPOD">POD</th>
                <th class="field-100" v-if="shouldShowFPD">FPD</th>
                <th class="field-60" v-if="shouldShowTranPort">Tran Port</th>
                <th class="field-60" v-if="shouldShowBooking">Booking no</th>
                <th class="field-60" v-if="shouldShowTranMode">Tran Mode</th>
                <th class="field-60">HAZ STAT</th>
                <th class="field-100">Note</th>
                <th class="field-100">Image</th>
                <th class="field-100" v-if="shouldShowSoundDamage">Sound/Damage</th>
              </tr>
            </thead>
            <tbody>
              <!-- Add Row -->
              <tr class="add-row">
                <!-- Your input fields here -->
                <td><input class="field-100" id="containerInput" v-model="newContainer.contnumber"
                    placeholder="Container Number" ref="containerInput" @blur="LoadContainerData" autofocus="true" />
                </td>
                <td>
                  <select class="field-100" v-model="selectedShortName">
                    <option value="">Select Party</option>
                    <option v-for="party in linkParties" :key="party.autoid" :value="party.autoid">
                      {{ party.short_name }}
                    </option>
                  </select>

                </td>
                <td>
                  <v-select class="field-100" :clearable="false" v-model="newContainer.vesselID" :options="vessels"
                    label="Name" :reduce="vessel => vessel.Vesselid" placeholder="Select a Vessel Name"
                    selectOnTab="True" />
                </td>
                <td>
                  <v-select class="field-100" :clearable="false" v-model="newContainer.VoyId" :options="voyage"
                    label="Name" :reduce="voyage => voyage.Voyageid" placeholder="Select a Voyage Name"
                    selectOnTab="True" />
                </td>
                <td>
                  <v-select class="field-100" :clearable="false" v-model="newContainer.movementID"
                    :options="movementOptions" label="Name" :reduce="movement => movement.Movid"
                    placeholder="Select Movement Name" selectOnTab="True" />
                </td>
                <td>
                  <v-select class="field-100" :clearable="false" v-model="newContainer.locID" :options="location"
                    label="Name" :reduce="locations => locations.locationid" placeholder="Select a Location Name"
                    selectOnTab="True" />
                </td>
                <td><input class="field-100" id="sealNo" v-model="newContainer.sealNo" placeholder="Seal No" /></td>
                <td>
                  <input class="field-100" type="text" placeholder="dd/mm/yyyy" v-model="newContainer.inspDate"
                    @blur="setValueToFiled()" />
                </td>
                <td>
                  <input class="field-100" type="text" v-model="newContainer.inspTime" />
                </td>
                <td class="field-100" v-if="shouldShowGrossWeight">
                  <input style="width: 60px;height: 40px;" type="number" v-model="newContainer.GrossWt"
                    placeholder="Gross Weight" />
                </td>
                <td v-if="shouldShowCFSID">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.cfsID" :options="cfs_location"
                    label="Name" :reduce="location => location.locationid" placeholder="Select CFS"
                    selectOnTab="True" />
                </td>
                <td v-if="shouldShowPOL">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.pol" :options="ports"
                    label="name" :reduce="port => port.portid" placeholder="Select POL" selectOnTab="True" />
                </td>
                <td v-if="shouldShowPOD">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.pod" :options="ports"
                    label="name" :reduce="port => port.portid" placeholder="Select POD" selectOnTab="True" />
                </td>
                <td v-if="shouldShowFPD">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.fpd" :options="ports"
                    label="name" :reduce="port => port.portid" placeholder="Select FPD" selectOnTab="True" />
                </td>
                <td class="field-60" v-if="shouldShowTranPort">
                  <select v-model="newContainer.transporter">
                    <option value="">Select Transporter</option>
                    <option value="ASL001">ALLIED ICD(DURGAPUR)</option>
                    <option value="CONKOL">CONCOR KOLKATA</option>
                    <option value="HCP001">HIND CARRIER</option>
                    <option value="HIN001">Hind Terminals Pvt. Ltd.</option>
                    <option value="IWL001">Inland world Logistics</option>
                    <option value="JSL001">JINDAL STAINLESS LTD.</option>
                    <option value="MMM001">MULTI TRANS MOVER</option>
                    <option value="RKE001">R. K. ENTERPRIZE</option>
                    <option value="RAD001">Radhika logistics</option>
                    <option value="TPR001">T.P.R.C.</option>
                    <option value="VED001">VED001</option>
                  </select>
                </td>
                <th class="field-60" v-if="shouldShowBooking">
                  <input type="text" id="bookingrelno" v-model="newContainer.bookingrelno" />
                </th>
                <td class="field-60" v-if="shouldShowTranMode">
                  <select v-model="newContainer.tranmode">
                    <option value=''>Transmode</option>
                    <option value="R">ROAD</option>
                    <option value="T">TRAIN</option>
                  </select>
                </td>
                <td>
                  <select class="field-60" v-model="newContainer.hazStat" placeholder="Select Hazard Status">
                    <option disabled value="">Select Hazard Status</option>
                    <option v-for="option in hazStatOptions" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                </td>
                <td><textarea class="field-100" v-model="newContainer.note" placeholder="Note" rows="3"></textarea></td>
                <td><input type="file" @change="onFileChange" ref="fileAdd" class="btn btn-default" multiple></td>
                <td v-if="shouldShowSoundDamage">
                  <div class="sound-damage-buttons field-100">
                    <div>
                      <input type="radio" id="sound" value="sound" v-model="newContainer.damage" checked="true"
                        @keydown.enter="handleCondition('Sound')">
                      <label for="sound">Sound</label>
                    </div>
                    <div>
                      <input type="radio" id="damage" value="damage" v-model="newContainer.damage"
                        @keydown.enter="handleCondition('Damage')">
                      <label for="damage">Damage</label>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- Last 5 Successful Tasks -->
              <tr v-for="container in lastFiveSuccessfulTasks" :key="container.autoID" class="successful-task">
                <td>{{ container.contnumber }}</td>
                <td>{{ container.short_name }}</td>
                <td>{{ container.vesselName }}</td>
                <td>{{ container.voyageName }}</td>
                <td>{{ container.movementName }}</td>
                <td>{{ container.locationName }}</td>
                <td>{{ container.sealNo }}</td>
                <td>{{ container.inspDate }}</td>
                <td>{{ container.inspTime }}</td>
                <td v-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
                <td v-if="shouldShowCFSID">{{ container.cfsID }}</td>
                <td v-if="shouldShowPOL">{{ container.pol }}</td>
                <td v-if="shouldShowPOD">{{ container.pod }}</td>
                <td v-if="shouldShowFPD">{{ container.fpd }}</td>
                <td>{{ container.hazStat }}</td>
                <td>{{ container.note }}</td>
                <td v-if="shouldShowSoundDamage">{{ container.damage }}</td>
              </tr>
              <!-- Container List -->
              <tr v-for="(container) in paginatedContainers" :key="container.autoID">
                <td>{{ container.contnumber }}</td>
                <td></td>
                <td>{{ container.VesselName }}</td>
                <td>{{ container.VoyageName }}</td>
                <td>{{ container.MovementName }}</td>
                <td>{{ container.LocationName }}</td>
                <td>{{ container.sealNo }}</td>
                <td>{{ container.inspDate }}</td>
                <td>{{ container.inspTime }}</td>
                <td v-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
                <td v-if="shouldShowCFSID">{{ container.cfsID }}</td>
                <td v-if="shouldShowPOL">{{ container.pol }}</td>
                <td v-if="shouldShowPOD">{{ container.pod }}</td>
                <td v-if="shouldShowFPD">{{ container.fpd }}</td>
                <td>{{ container.hazStat }}</td>
                <td>{{ container.note }}</td>
                <td v-if="shouldShowSoundDamage">{{ container.damage }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Damage Details</h2>
          <form @submit.prevent="saveDamageDetails">
            <div class="overlay-form-group">
              <label for="location">Location:</label>
              <v-select :clearable="false" style="width: 250px;" id="location" v-model="damageDetails.location"
                :options="locations" label="Name" :reduce="loc => loc.id" class="overlay-form-select" selectOnTab="True"
                placeholder="Select Location" />
              <label for="position">Position:</label>
              <v-select :clearable="false" style="width: 250px;" id="position" v-model="damageDetails.position"
                :options="positions" :reduce="pos => pos.id" placeholder="Select Position" label="name"
                selectOnTab="True" class="overlay-form-select" />

            </div>
            <div class="overlay-form-group">
              <label for="damageFrom">Damage From:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" placeholder="Select Damage From"
                v-model="damageDetails.damageFrom" :options="FromSec1" :reduce="fromsec => fromsec.id" label="name"
                class="overlay-form-select" selectOnTab="True" append-to-body />

              <label for="damageFrom">&</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" v-model="damageDetails.damageFromAnd"
                :options="FromSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                selectOnTab="True" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label for="damageTo">Damage To:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageTo"
                :options="ToSec1" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                append-to-body placeholder="Select Damage To" selectOnTab="True" />

              <label for="damageTo">&:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageToAnd"
                :options="ToSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                selectOnTab="True" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label style="width:100px" for="component">Component:</label>
              <v-select :clearable="false" style="width: 120px;" id="component" v-model="damageDetails.component"
                :options="components" label="description" :reduce="component => component.id" class="overlay-form-input"
                placeholder="Select Component" selectOnTab="True" />

              <label for="damageType">Damage Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="damageType" v-model="damageDetails.damageType"
                :options="damageTypes" label="description" :reduce="damageType => damageType.id"
                class="overlay-form-input" placeholder="Select Damage Type " selectOnTab="True" />

              <label for="repairType">Repair Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="repairType" v-model="damageDetails.repairType"
                :options="repairTypes" label="description" :reduce="repairType => repairType.id"
                class="overlay-form-input" placeholder="Select Repair Type" selectOnTab="True" />
            </div>
            <div class="overlay-form-group">
              <label for="length">Length:</label>
              <input style="width: 60px;" type="number" value="0" id="length" v-model="damageDetails.length"
                class="overlay-form-input" />
              <label for="breadth">Breadth:</label>
              <input style="width: 60px;" type="number" id="breadth" v-model="damageDetails.breadth"
                class="overlay-form-input" />
              <label for="area">Area:</label>
              <input style="width: 60px;" type="number" id="area" value="0" v-model="damageDetails.area"
                class="overlay-form-input" />
              <label for="quantity">Quantity:</label>
              <input style="width: 60px;" type="number" id="quantity" value="0" v-model="damageDetails.quantity"
                class="overlay-form-input" />
            </div>
            <div class="overlay-form-group">
              <label for="damenote">Note:</label>
              <textarea style="width: 80%;" type="text" value="0" id="damenote" v-model="damageDetails.note"
                class="overlay-form-input"></textarea>
            </div>

            <div class="overlay-form-group">
              <label for="dameimage">Image:</label>
              <input type="file" @change="onDameFileChange" ref="fileDamAdd" />
            </div>


            <button type="submit">Save</button>
            <button class="cancel-button" @click="closeOverlay">Cancel</button>
          </form>

          <!-- Table to display saved damage details -->
          <div v-if="damageDetailsList.length > 0">
            <table class="damage-details-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Position</th>
                  <th>Damage From</th>
                  <th>Damage To</th>
                  <th>Component</th>
                  <th>Damage Type</th>
                  <th>Repair Type</th>
                  <th>Length</th>
                  <th>Breadth</th>
                  <th>Area</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail, index) in damageDetailsList" :key="index">
                  <td>{{ detail.location.Name }}</td>
                  <td>{{ detail.position.name }}</td>
                  <td>{{ detail.damageFrom.name }}</td>
                  <td>{{ detail.damageTo.name }}</td>
                  <td>{{ detail.component }}</td>
                  <td>{{ detail.damageType }}</td>
                  <td>{{ detail.repairType }}</td>
                  <td>{{ detail.length }}</td>
                  <td>{{ detail.breadth }}</td>
                  <td>{{ detail.area }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </table>
            <button class="done-button" @click="done">Done</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>


<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import 'form-data';
import * as cs from '../assets/js/commonscript.js'

//import fs from 'node:fs';


export default {
  components: {
    VSelect,
  },
  data() {
    return {
      containerdata: [],
      containers: [],
      linkParties: JSON.parse(localStorage.getItem('party')),
      selectedShortName: '',
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      vessels: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      movementOptions: JSON.parse(localStorage.getItem('movements')) || [],
      ports: JSON.parse(localStorage.getItem('ports')) || [],
      location: JSON.parse(localStorage.getItem('locations')) || [],
      cfs_location: JSON.parse(localStorage.getItem('cfs_location')) || [],

      locations: [
        { id: 0, name: 'Select One' },
        { id: 1, Name: 'EXT' },
        { id: 2, Name: 'INT' },],
      positions: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Left' },
        { id: 2, name: 'Front' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Top' },
        { id: 5, name: 'Rear' },
        { id: 6, name: 'Interior' },
        { id: 7, name: 'Under Structure' },
        { id: 8, name: 'Exterior' },
      ],
      FromSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      FromSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      ToSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      ToSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      isLoading: false,
      error: null,
      hasPermissions: true,
      rowsPerPage: 10,
      currentPage: 1,
      imagefiles: [],
      newContainer: {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: 0,
        applicantname: '',
        accountID: 0,
        accountname: '',
        vesselID: 0,
        VesselName: '',
        VoyId: 0,
        VoyageName: '',
        containerID: 0,
        contnumber: '',
        movementID: 0,
        inspectionId: null,
        MovementName: '',
        locID: 0,
        short_name: '',
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        transporter: '',
        bookingrelno: '',
        tranmode: '',
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      },
      successfulTasks: [],
      hazStatOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],

      damageDetails: {
        location: 0,
        position: 0,
        damageFrom: 0,
        damageFromAnd: 0,
        damageTo: 0,
        damageToAnd: 0,
        component: 0,
        damageType: 0,
        repairType: 0,
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0,
        note: '',
        Image: null
      },

      damageDetailsList: [],
      damageDbList: [],
      showOverlay: false,
    };
  },
  async mounted() {
    //alert("hi")
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // run code here
        document.getElementById("containerInput").focus();

        this.$refs.containerInput.focus();
      }
    }
  },
  async updated() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // run code here
        document.getElementById("containerInput").focus();

        this.$refs.containerInput.focus();
      }
    }
  },
  computed: {
    maxDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(today.getDate()).padStart(2, '0');
      return`${day}/${month}/${year}`;
    },
    lastFiveSuccessfulTasks() {
      //console.log(this.successfulTasks);
      return this.successfulTasks.slice(-5).reverse();
    },
    paginatedContainers() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.containers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.containers.length / this.rowsPerPage);
    },
    shouldShowISPTDate() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowTime() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowGrossWeight() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowCFSID() {
      return ['23', '24', '42', '43'].includes(String(this.newContainer.movementID));
    },
    shouldShowTranPort() {
      return ['21', '35', '38', '39', '42', '43', '44'].includes(String(this.newContainer.movementID));
    },
    shouldShowBooking() {
      return ['21', '29', '35', '38', '39', '42', '43', '44'].includes(String(this.newContainer.movementID));
    },
    shouldShowTranMode() {
      return ['22', '25', '30', '31'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOL() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowFPD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowSoundDamage() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    }
  },
  created() {
    this.fetchLinkParties(); // Fetch link parties
    let keysPressed = {};
    document.addEventListener('keydown', (e) => {
      keysPressed[e.key] = true;
      if (keysPressed['Alt'] && e.key == 's') {
        this.newContainer.soundDamage = 'Sound'
        this.handleCondition('Sound')
      }
      else if (keysPressed['Alt'] && e.key == 'd') {
        this.newContainer.soundDamage = 'Damage'
        this.handleCondition();
      }
      document.addEventListener('keyup', (e) => {
        delete keysPressed[e.key];
      });
    });
  },
  methods: {
    setValueToFiled() {
      if (this.newContainer.inspDate != '') {
        if (cs.isvalidDate(this.newContainer.inspDate) == false) {
          useToast().error('Invalid Date');
        }
      }
      if (this.newContainer.movementID == 25) {
        this.newContainer.tranmode = 'R'
        if (this.newContainer.cfsID == 624) {
          this.newContainer.tranmode = 'R'
        }
      } else {
        this.newContainer.tranmode = ''
      }
    },
    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.imagefiles.push(selectedFiles[i]);
      }
    },
    async LoadContainerData() {
      const contnum = this.newContainer.contnumber
      if (contnum == "")
        return;
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get(`/getContainerDetails?containerno=${contnum}`, config);
        this.containerdata = response.data[2];
        console.log(this.containerdata)
        if (this.containerdata !== null) {
          this.selectedShortName = this.containerdata.PartyLinkId
          this.newContainer.containerID = this.containerdata.containerId
          this.newContainer.vesselID = this.containerdata.vesselld
          this.newContainer.VoyId = this.containerdata.voyage
          this.newContainer.movementID = this.containerdata.movementId
          this.newContainer.locID = this.containerdata.inspectionplace
          this.newContainer.containerID = this.containerdata.containerID
          this.newContainer.cfsID = this.containerdata.cfsID
          this.newContainer.pol = this.containerdata.LoadPort
          this.newContainer.pod = this.containerdata.DestnPort
          this.newContainer.fpd = this.containerdata.transport
          this.newContainer.note = this.containerdata.note
          this.newContainer.GrossWt = this.containerdata.cargowt
          
          document.getElementById("sealNo").focus();
        } else {
          //console.log("Container Details not found")
          useToast().error("Container Details not found")
          this.selectedShortName = ''
          this.newContainer.containerID = ''
          this.newContainer.vesselID = ''
          this.newContainer.VoyId = ''
          this.newContainer.movementID = ''
          this.newContainer.locID = ''
        }
      }
      catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }
    },
    async fetchLinkParties() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get('/linkParties', config);
        this.linkParties = response.data[2]; // Assuming the link parties are in the third element of the response array
      } catch (error) {
        console.error('Error fetching link parties:', error);
        this.error = 'Error fetching link parties. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    validateRequiredFields() {
      if (!this.newContainer.contnumber || !this.newContainer.VesselName || !this.newContainer.VoyageName) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields.');
        return false;
      }
      return true;
    },

    getNameById(array, id, key = 'id', nameKey = 'name') {
      const item = array.find(item => item[key] === id);
      return item ? item[nameKey] : '';
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
    async handleCondition(conditionType) {
      if (!this.newContainer.contnumber || !this.newContainer.vesselID || !this.newContainer.VoyId || !this.newContainer.movementID || !this.newContainer.locID || !this.newContainer.inspDate || !this.newContainer.inspTime || !this.newContainer.hazStat) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields before selecting Sound or Damage.');
        return;
      }
      const party = this.linkParties.find(party => party.autoid === this.selectedShortName).short_name;
      //console.log(party)
      const conditionId = conditionType === 'Sound' ? 2 : 1;

      const AppAcc = cs.GetAppAccFromParty(this.selectedShortName)
      this.newContainer.applicantID = AppAcc.applicantid
      this.newContainer.accountID = AppAcc.accountid
      if (this.newContainer.movementID == 25) {
        this.newContainer.cfsID = 0
      }
      const transactionData = {
        containerId: this.newContainer.containerID || 0,
        containerNumber: this.newContainer.contnumber || '',
        applicant: this.newContainer.applicantID || 0,
        account: this.newContainer.accountID || 0,
        vesselld: this.newContainer.vesselID || 0,
        voyage: this.newContainer.VoyId || 0,
        movementId: this.newContainer.movementID || 0,
        locationId: this.newContainer.locID || 0,
        tolocation: this.newContainer.cfsID || 0,
        cfsName: this.newContainer.cfsName, // Use saved name
        seal: this.newContainer.sealNo || '',
        inspdate: cs.ConvertToISODate(this.newContainer.inspDate || this.getCurrentDate()),
        insptime: this.newContainer.inspTime || '',
        conditionid: conditionId,
        inspectionplace: this.newContainer.locID,
        condition: conditionType,
        note: this.newContainer.note || '',
        cargowt: this.newContainer.GrossWt || 0,
        hazstat: this.newContainer.hazStat || 'N',
        loadport: this.newContainer.pol || 0,
        loadPortName: this.newContainer.loadPortName, // Use saved name
        destnport: this.newContainer.pod || 0,
        destnPortName: this.newContainer.destnPortName, // Use saved name
        transport: this.newContainer.fpd || 0,
        transportName: this.newContainer.transportName, // Use saved name
        transporter: this.newContainer.transporter,
        tranmode: this.newContainer.tranmode,
        bookingrelno: this.newContainer.bookingrelno,
      };
      const vesselName = this.getNameById(this.vessels, this.newContainer.vesselID, 'Vesselid', 'Name');
      const voyageName = this.getNameById(this.voyage, this.newContainer.VoyId, 'Voyageid', 'Name');
      const locationName = this.getNameById(this.location, this.newContainer.locID, 'locationid', 'Name');
      const movementName = this.getNameById(this.movementOptions, this.newContainer.movementID, 'Movid', 'Name');

      console.log(transactionData)

      //let data = new FormData();
      //
      //data.append('transaction',transactionData)
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'multipart/form-data'
          },
        };
        const data = new FormData();
        const x = JSON.stringify(transactionData)
        console.log(x)
        data.append('transaction', x)
        console.log(data)
        //var fileInput = document.getElementById('fileAdd');
        const fileInput = this.$refs.fileAdd;

        for (const file of fileInput.files) {
          data.append("files", file);
        }

        const response = await axios.post('/create_transaction', data, config);
        if (response.data[0] === 0) {
          // Display success message using the second element of the response
          useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.successfulTasks.push({
            ...this.newContainer, conditionType,
            sealNo: this.newContainer.sealNo,
            note: this.newContainer.note,
            vesselName,
            voyageName,
            locationName,
            movementName,
            short_name: party
          });
          //console.log(this.successfulTasks, 'hello')
          this.resetNewContainer();
          //const focuscontnumberfld = this.$refs.containerInput[0]; // Assuming it's a ref array, hence [0]
          //focuscontnumberfld.focus();  // Set focus on the input field
          document.getElementById("containerInput").focus();
          document.getElementById("sound").checked = true;

        }
      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }

      /*
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://127.0.0.1:8000/create_transaction',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1LCJuYW1lIjoiTGFyZG5lciBVc2VyIiwicm9sZWlkIjo0LCJjb21wYW55aWQiOjIsImV4cCI6MTcyNzI1NzMxMH0.aWWVZ4p7OCdxeW7pm6Ymt_BX4vxOpnRIDPzOu1m5TTA',
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          useToast().success('Created Succesful');
          this.successfulTasks.push({
            ...this.newContainer, conditionType,
            sealNo: this.newContainer.sealNo,
            note: this.newContainer.note,
            vesselName,
            voyageName,
            locationName,
            movementName,
            short_name: party
          });
          //console.log(this.successfulTasks, 'hello')
          this.resetNewContainer();
        })
        .catch((error) => {
          //console.log(error);

        });
*/

      /*
      const uploadData = new FormData();
      const payloadx = [];
      uploadData.append("transaction", transactionData)
      var dict = {};
      dict.transaction=transactionData
      payloadx.push(dict)
      //uploadData.append("files", this.$refs.fileAdd.files);
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'multipart/form-data'
          },
        };
        const payload = JSON.stringify(dict)
        const response = await axios.post('/create_transaction', payload, config);
        if (response.data[0] === 0) {
          // Display success message using the second element of the response
          useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.successfulTasks.push({
            ...this.newContainer, conditionType,
            sealNo: this.newContainer.sealNo,
            note: this.newContainer.note,
            vesselName,
            voyageName,
            locationName,
            movementName,
            short_name: party
          });
          //console.log(this.successfulTasks, 'hello')
          this.resetNewContainer();
        }

      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      } */
    },
    addContainer() {
      this.handleCondition('Sound');
    },

    resetNewContainer() {
      this.newContainer = {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: null,
        applicantname: '',
        accountID: null,
        accountname: '',
        vesselID: null,
        VesselName: '',
        VoyId: null,
        VoyageName: '',
        containerID: null,
        contnumber: '',
        movementID: null,
        MovementName: '',
        locID: null,
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      };
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1;
    },
  },
};
</script>


<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  width: 100%;
  height: 50%;
}

.container-list {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  /* Center the container */
}

.sound-damage-buttons {
  display: flex;
  flex-direction: column;
  /* Align buttons vertically */
  gap: 10px;
  /* Space between the button groups */
}

.sound-damage-buttons div {
  display: flex;
  align-items: center;
  /* Align radio button and label vertically */
  margin-bottom: 8px;
  /* Space between individual radio button groups */
}

.sound-damage-buttons input[type="radio"] {
  margin-right: 5px;
  /* Reduced space between radio button and label */
  accent-color: #007bff;
  /* Custom color for the radio button */
}

.sound-damage-buttons label {
  font-size: 16px;
  /* Adjust font size for better readability */
  margin: 0;
  /* Remove extra margin around the label */
}

/* Optional: Adjust the size of radio buttons if necessary */
.sound-damage-buttons input[type="radio"] {
  width: 16px;
  /* Size of the radio button */
  height: 16px;
  /* Size of the radio button */
}



.table-container {
  overflow-x: auto;
  border-radius: 20px;
  height: 400px;
  width: auto;
}

.movement-table {
  background-color: #fff;
}

.movement-table th {
  background-color: #f8f8f8;
}

.movement-table tr:hover {
  background-color: #f1f1f1;
}

.successful-task {
  background-color: #e0ffe0;
  /* Light green background for successful tasks */
}

.add-row {
  background-color: #e8f0fe;
  /* Light blue background for the add row */
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.rows-per-page {
  display: flex;
  align-items: center;
}

.rows-per-page span {
  margin-right: 8px;
}

.rows-per-page button {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
}

.rows-per-page button.active {
  background-color: #007bff;
  color: #fff;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* Allow scrolling if content overflows */
  z-index: 1000;
  /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  /* Allow more width */
  max-height: 90%;
  /* Restrict height to avoid overflow */
  overflow-y: auto;
  /* Scroll if content overflows vertically */
  z-index: 1001;
  /* Ensure content is above overlay background */
}

/* Overlay form group styling */
/* Overlay form group styling */
.overlay-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* Add space between form groups */
  gap: 5px;
  /* Add space between fields */
}

/* Styling for labels to ensure alignment */
.overlay-form-group label {
  margin-right: 2px;
  width: 70px;
  /* Set a fixed width for labels */
}

/* Styling for input and select elements to take available space */

.overlay-form-group .overlay-form-select {
  width: 350px;
  /* adjust the width to your desired size */
  font-size: 12px;
  /* adjust the font size to your desired size */
}
</style>